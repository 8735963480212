
import image from "../../assets/images/istockphoto-1460768388-612x612.png"
import Layout from "../../hocs/Layout";
import { useDispatch } from "react-redux";
import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { useEffect } from "react";
import { clearProductToBag } from "../../features/bag/bagSlice";

const Success = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(clearProductToBag());
    }, [])
    return (
        <Layout>
            <div className="w-full h-screen max-h-[calc(100vh-3.5rem)] relative overflow-hidden">             
                <div className="max-w-7xl mx-auto bg-gray-50 rounded-md">
                    <div className="p-24 md:p-64">
                        <div className="flex justify-center items-center flex-col md:flex-row">
                            <div className="pb-5 md:pr-16 flex flex-col justify-center items-center">
                                <h2 className="font-HelveticaNueUltraLight text-3xl tracking-0.25rem whitespace-pre-line text-center">Thanks for your payment</h2>
                                <p className="font-HelveticaNueUltraLight text-center">soon you will receive information about your product</p>
                            </div>
                            <ShoppingBagIcon className="w-12 h-12"/>
                        </div>
                    </div>
                </div>
                <img className="object-cover absolute -bottom-20 sm:-bottom-52 md:-bottom-36 -left-36 " src={image}/>
            </div>
        </Layout>
    )
}

export default Success;
