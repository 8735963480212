import { useTranslation } from "react-i18next";
import FormStarRating from "./StarRating";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import "../../styles/comment.css";
import RenderStars from "../Products/ViewStarRating";
import React, { useRef, useEffect, useState } from "react";
import CommentPopUp from "./CommentPopUp";

const Comments = ({
   allComments,
   onRatingChange,
   handleFileChange,
   handleSubmit,
   handleWriteCommnet,
   onChange,
   handleFocus,
   handleBlur,
   formData,
   focusedField,
   fileInputRef,
   displayedComments,
   popUpComment,
   imagePreview,
   page,
   setPage,
   totalPages,
}) => {
   const { t } = useTranslation("product");
   const commentContainer = useRef(null);
   const [imagePopUp, setImagePopUp] = useState(null);
   const [commentOnClose, setCommentOnClose] = useState(true);
   const [commentPayload, setCommentPayload] = useState("");
   // const [commentClassPopUp, setCommentClassPopUp] = useState();

   const handlePageClick = (pageNumber) => {
      setPage(pageNumber);

      setTimeout(() => {
         if (commentContainer.current) {
            const rect = commentContainer.current.getBoundingClientRect();
            const top = rect.top + window.scrollY;
            window.scrollTo({
               top: top,
            });
         }
      }, 100);
   };

   const handleNextPage = () => {
      setPage(page + 1 <= totalPages ? page + 1 : page);
      setTimeout(() => {
         if (commentContainer.current) {
            const rect = commentContainer.current.getBoundingClientRect();
            const top = rect.top + window.scrollY;
            window.scrollTo({
               top: top,
            });
         }
      }, 100);
   };
   const handlePrevPage = () => {
      setPage(page - 1 >= 1 ? page - 1 : page);
      setTimeout(() => {
         if (commentContainer.current) {
            const rect = commentContainer.current.getBoundingClientRect();
            const top = rect.top + window.scrollY;
            window.scrollTo({
               top: top,
            });
         }
      }, 100);
   };

   const handleImagePopUp = (e, comment) => {
      document.body.style.overflowY = 'hidden';
      setCommentPayload(comment);
      setImagePopUp(e.target.src);
      setCommentOnClose(false);
      // setCommentClassPopUp("bg-black pointer-events-auto");
   };

   return (
      <>
         <CommentPopUp
            imagePopUp={imagePopUp}
            setImagePopUp={setImagePopUp}
            commentOnClose={commentOnClose}
            setCommentOnClose={setCommentOnClose}
            commentPayload={commentPayload}
            setCommentPayload={setCommentPayload}
         />

         <div className="comment-section">
            <header className="w-full ml-auto pt-16 pb-3 md:pt-40 md:pb-3 comment-section-header">
               {allComments && (
                  <div className="flex flex-col items-baseline justify-center">
                     <div className="px-6 pb-10 pt-10">
                        <h3 className="font-HelveticaNueRegular text-4xl md:text-6xl tracking-0.25rem text-center">
                           {t("productCommentSection.productCommetText")}
                        </h3>
                     </div>
                     <div className="w-full flex items-start justify-start px-7 md:px-6 py-2 flex-wrap">
                        <div className="flex flex-col items-center">
                           <h3 className="font-HelveticaNueExtraBold tracking-0.25rem text-3xl md:text-5xl pb-1 md:pb-2">
                              {allComments.totalComments}
                           </h3>
                           <span className="font-HelveticaNueRegular text-base pb-0.5 text-subtitleColor">
                              Reviews
                           </span>
                           <div className="flex m-0">
                              <RenderStars
                                 width={5}
                                 height={5}
                                 averageRating={allComments.averageRating}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </header>
            <div
               className="w-full h-auto grid-comments px-6 "
               ref={commentContainer}
            >
               {displayedComments &&
                  displayedComments.map((comment, index) => (
                     <ul className="w-full" key={index}>
                        <li className="py-12 border border-b-gray-200 border-x-transparent border-y-transparent">
                           <header className="w-full py-4">
                              <h4 className="font-HelveticaNueRegular text-lg tracking-0.25rem pb-1">
                                 {comment.formattedName}
                              </h4>
                              <div className="flex">
                                 <RenderStars
                                    width={4}
                                    height={4}
                                    averageRating={comment.commentRating}
                                 />
                              </div>
                           </header>
                           <div className="pb-5 pr-7 md:max-w-xl">
                              <p className="font-HelveticaNueThin text-sm text-gray-950 tracking-0.25rem whitespace-pre-wrap">
                                 {comment.commentBodyReview}
                              </p>
                           </div>
                           <footer className="">
                              <div className="w-20 h-20 overflow-hidden rounded-sm">
                                 <img
                                    onClick={(e) => handleImagePopUp(e, comment)}
                                    className="object-cover w-full h-full card-comment-wrapper-img cursor-pointer"
                                    src={`${comment.commentImageReview}`}
                                    alt={`Image for ${comment.formattedName}`}
                                 />
                              </div>
                           </footer>
                        </li>
                     </ul>
                  ))}
            </div>
            <div className="w-full flex justify-end items-center px-5 ">
               <div className="w-full flex justify-center md:justify-end items-center footer-pagination py-5">
                  <ChevronLeftIcon
                     className="w-5 h-4 cursor-pointer"
                     onClick={() => handlePrevPage()}
                  />
                  <ul className="flex px-4">
                     {[...Array(totalPages).keys()].map((i) => (
                        <li
                           key={i + 1}
                           className={`page-item px-3 mx-2 cursor-pointer text-sm font-HelveticaNueThin select-none ${
                              page === i + 1
                                 ? "text-black"
                                 : "text-subtitleColor"
                           }`}
                           onClick={() => handlePageClick(i + 1)}
                        >
                           {i + 1}
                        </li>
                     ))}
                  </ul>
                  <ChevronRightIcon
                     className="w-5 h-4 cursor-pointer"
                     onClick={() => handleNextPage()}
                  />
               </div>
            </div>
            {/* <div className="w-full px-6 py-20">
               <h3 className="font-HelveticaNueRegular tracking-0.25rem pb-4">
                  Do you want to write <br />
                  your experience?
               </h3>
               <button
                  className="font-HelveticaNueThin tracking-0.25rem cursor-pointer"
                  onClick={handleWriteCommnet}
                  title="Write a Review"
               >
                  Write a review
               </button>
               <div
                  className={`w-full overflow-hidden ${
                     popUpComment ? "bg-comment-show" : "bg-comment-hidden"
                  }`}
               >
                  <div className="w-full relative px">
                     <XMarkIcon
                        className="w-6 h-6 absolute top-0 right-0 cursor-pointer"
                        onClick={handleWriteCommnet}
                     />
                     <div className="w-full">
                        <form
                           onSubmit={handleSubmit}
                           className="w-full grid grid-comment-template grid-cols-[repeat(1,minmax(0,1fr))] justify-center gap-y-4 md:grid-cols-[repeat(1,minmax(0,1fr))]"
                        >
                           <div className="w-full flex flex-wrap gap-y-4 gap-x-3">
                              <div className="relative flex justify-start items-center perspective-container">
                                 <label
                                    className={`font-HelveticaNueThin absolute ${
                                       focusedField === "comment_first_name" ||
                                       formData.comment_first_name
                                          ? "label-name"
                                          : "label-name-normal"
                                    }`}
                                    htmlFor="comment_first_name"
                                 >
                                    <span
                                       className={`font-HelveticaNueThin text-base ${
                                          focusedField ===
                                             "comment_first_name" ||
                                          formData.comment_first_name
                                             ? "text-black"
                                             : "text-inputColor"
                                       }`}
                                    >
                                       First Name
                                    </span>
                                 </label>
                                 <input
                                    className={`font-HelveticaNueThin bg-transparent outline-none px-2 py-3 tracking-0.4px rounded-lg border ${
                                       focusedField === "comment_first_name" ||
                                       formData.comment_first_name
                                          ? "border-black"
                                          : "border-inputColor"
                                    }`}
                                    type="text"
                                    name="comment_first_name"
                                    onFocus={() =>
                                       handleFocus("comment_first_name")
                                    }
                                    onBlur={() =>
                                       handleBlur("comment_first_name")
                                    }
                                    onChange={onChange}
                                    value={formData.comment_first_name}
                                    required
                                 />
                              </div>
                              <div className="relative flex justify-start items-center perspective-container">
                                 <label
                                    className={`font-HelveticaNueThin absolute ${
                                       focusedField === "comment_last_name" ||
                                       formData.comment_last_name
                                          ? "label-name"
                                          : "label-name-normal"
                                    }`}
                                    htmlFor="comment_last_name"
                                 >
                                    <span
                                       className={`font-HelveticaNueThin text-base ${
                                          focusedField ===
                                             "comment_last_name" ||
                                          formData.comment_last_name
                                             ? "text-black"
                                             : "text-inputColor"
                                       }`}
                                    >
                                       Last Name
                                    </span>
                                 </label>
                                 <input
                                    className={`font-HelveticaNueThin bg-transparent outline-none px-2 py-3 tracking-0.4px rounded-lg border ${
                                       focusedField === "comment_last_name" ||
                                       formData.comment_last_name
                                          ? "border-black"
                                          : "border-inputColor"
                                    }`}
                                    type="text"
                                    name="comment_last_name"
                                    onFocus={() =>
                                       handleFocus("comment_last_name")
                                    }
                                    onBlur={() =>
                                       handleBlur("comment_last_name")
                                    }
                                    onChange={onChange}
                                    value={formData.comment_last_name}
                                    required
                                 />
                              </div>
                              <div className="relative flex justify-start items-center perspective-container">
                                 <label
                                    className={`font-HelveticaNueThin absolute ${
                                       focusedField === "comment_email" ||
                                       formData.comment_email
                                          ? "label-name"
                                          : "label-name-normal"
                                    }`}
                                    htmlFor="comment_email"
                                 >
                                    <span
                                       className={`font-HelveticaNueThin text-base ${
                                          focusedField === "comment_email" ||
                                          formData.comment_email
                                             ? "text-black"
                                             : "text-inputColor"
                                       }`}
                                    >
                                       Email
                                    </span>
                                 </label>
                                 <input
                                    className={`font-HelveticaNueThin bg-transparent outline-none px-2 py-3 tracking-0.4px rounded-lg border ${
                                       focusedField === "comment_email" ||
                                       formData.comment_email
                                          ? "border-black"
                                          : "border-inputColor"
                                    }`}
                                    type="email"
                                    name="comment_email"
                                    onFocus={() => handleFocus("comment_email")}
                                    onBlur={() => handleBlur("comment_email")}
                                    onChange={onChange}
                                    value={formData.comment_email}
                                    required
                                 />
                              </div>
                           </div>

                           <div className="relative w-full md:9/12 flex justify-start items-center perspective-container">
                              <textarea
                                 className={`w-full max-h-72 min-h-72 font-HelveticaNueThin bg-transparent outline-none px-2 py-3 tracking-0.4px rounded-lg resize-none border form-textarea placeholder:tracking-0.25rem ${
                                    focusedField === "comment_body_review" ||
                                    formData.comment_body_review
                                       ? "border-black"
                                       : "border-inputColor"
                                 }`}
                                 name="comment_body_review"
                                 placeholder="Write your comment.."
                                 onFocus={() =>
                                    handleFocus("comment_body_review")
                                 }
                                 onBlur={() =>
                                    handleBlur("comment_body_review")
                                 }
                                 onChange={onChange}
                                 value={formData.comment_body_review}
                                 required
                              />
                           </div>

                           <div className="py-2">
                              <header className="w-full py-2">
                                 <h3 className="font-HelveticaNueRegular text-inputColor tracking-0.25rem">
                                    Rating
                                 </h3>
                              </header>
                              <FormStarRating
                                 rating={formData.comment_rating}
                                 onRatingChange={onRatingChange}
                              />
                           </div>
                           <div
                              className="w-full max-w-96 border-2 border-inputColor border-dashed max-h-72 min-h-72 flex justify-center items-center rounded-lg cursor-pointer"
                              onClick={handleFileChange}
                           >
                              <input
                                 className="hidden"
                                 name="comment_image_review"
                                 type="file"
                                 accept="image/*"
                                 onChange={onChange}
                                 ref={fileInputRef}
                                 required
                              />
                              {imagePreview ? (
                                 <img
                                    className="w-full max-w-full h-full object-cover"
                                    src={imagePreview}
                                    alt="Selected Preview"
                                 />
                              ) : (
                                 <PhotoIcon className="w-10 h-10 text-inputColor" />
                              )}
                           </div>
                           <button className="w-full font-HelveticaNueRegular bg-black text-white py-2  tracking-0.25rem text-sm rounded-sm border border-black">
                              Public
                           </button>
                        </form>
                     </div>
                  </div>
               </div>
            </div> */}
         </div>
      </>
      // 60 in movile
   );
};

export default Comments;
