import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addProductToBag } from "../../features/bag/bagSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";

import Comments from "../../components/Comments/Comments";
import ProductPopUp from "../../components/Products/ProductPopUp";
// Icons

import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";

// SVG
import Package from "../../assets/images/svg/package.svg";
import Check from "../../assets/images/svg/check.svg";

// Images

import Layout from "../../hocs/Layout";

import "../../styles//Product/product.css";

import { useTranslation } from "react-i18next";
import RenderStars from "../../components/Products/ViewStarRating";
// import CommentPopUp from "../../components/Comments/CommentPopUp";
import "../../assets/fonts/Inter-4.0/web/inter.css";

const Product = () => {
   const { t } = useTranslation("product");
   const params = useParams();
   const dispatch = useDispatch();
   const language = useSelector((state) => state.bag.language);
   const { countProduct, userUUID } = useSelector((state) => state.bag);
   const [productData, setProductData] = useState(null);

   const [popUpBackground, setPopUpBackground] = useState(false);
   const [popUpProduct, setPopUpProduct] = useState(false);
   const [popUpProductWarning, setPopUpProductWarning] = useState(false);

   const [open1, setOpen1] = useState(false);
   const [open2, setOpen2] = useState(false);

   const fileInputRef = useRef(null);
   const [imagePreview, setImagePreview] = useState(null);
   const timezone = "America/Los_Angeles";
   const [allComments, setAllComments] = useState([]);
   const [displayedComments, setDisplayedComments] = useState([]);
   // const [emptyStars, setEmptyStars] = useState(5);
   // const [hasMore, setHasMore] = useState(true);
   const [page, setPage] = useState(1);
   const [totalPages, setTotalPages] = useState(0);

   useEffect(() => {
      fetchDisplayedComments();
   }, [page]);

   useEffect(() => {
      fetchAllComments();
   }, [page]);

   useEffect(() => {
      window.scrollTo(0, 0);
      const optionLanguage = navigator.languages[0].split("-")[0];
      const productsDetails = async () => {
         const config = {
            headers: {
               "Content-Type": "application/json",
               "Accept-Language": optionLanguage,
            },
         };

         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/product-content/${params.productId}/`,
               config
            );
            setProductData(response.data);
         } catch (err) {
            console.log(err);
         }
      };

      productsDetails();
   }, [params.productId]);
   
   const handleAddBag = async (e) => {
      e.preventDefault();
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };

      const body = JSON.stringify({
         client_uuid: userUUID,
         product_uuid: params.productId,
         product_quantity: 1,
      });

      const currentProduct = {
         productId: productData.product.productId,
         productCategory: productData.product.translations.productCategory,
         productName: productData.product.translations.productName,
         productThumbnail: productData.product.productThumbnail,
         productPrice: productData.product.productPrice,
         productTranslations: productData.product.translations,
         productQuantity: 1,
      };

      const clientAddCart = async (body) => {
         try {
            axios.post(
               `${process.env.REACT_APP_API_URL}/client-add-to-bag/`,
               body,
               config
            );
         } catch (err) {
            console.log(err);
         }
      };

      if (countProduct < 10) {
         await clientAddCart(body);
         dispatch(addProductToBag(currentProduct));
         setPopUpProduct(true);
         setPopUpBackground(true);
      } else {
         setPopUpBackground(true);
         setPopUpProductWarning(true);
      }
   };

   const [formData, setFormData] = useState({
      comment_first_name: "",
      comment_last_name: "",
      comment_email: "",
      comment_body_review: "",
      comment_image_review: null,
      comment_rating: 0,
      comment_language: navigator.languages[0].split("-")[0],
      comment_date: moment().tz(timezone).format("YYYY-MM-DD"),
      product_id: params.productId,
   });

   const fetchDisplayedComments = async () => {
      const optionLanguage = navigator.languages[0].split("-")[0];
      try {
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
            params: {
               comment_language: optionLanguage,
               product_id: params.productId,
               page: page,
            },
         };
         const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/product-comments/`,
            config
         );
         if (response.data.results.length === 0) {
            // setHasMore(false);
         } else {
            setDisplayedComments(response.data.results);
            setTotalPages(parseInt(response.data.totalPages));
         }
      } catch (error) {
         console.error("Error fetching comments:", error);
      }
   };

   const fetchAllComments = async () => {
      const optionLanguage = navigator.languages[0].split("-")[0];
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };
      const body = {
         product_id: params.productId,
         comment_language: optionLanguage,
      };
      const response = await axios.post(
         `${process.env.REACT_APP_API_URL}/product-comments-summary/`,
         body,
         config
      );
      setAllComments(response.data);
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      try {
         const formDataToSend = new FormData();
         formDataToSend.append(
            "comment_name",
            `${formData.comment_first_name} ${formData.comment_last_name}`
         );
         formDataToSend.append("comment_email", formData.comment_email);
         formDataToSend.append(
            "comment_body_review",
            formData.comment_body_review
         );
         formDataToSend.append("comment_rating", formData.comment_rating);
         formDataToSend.append(
            "comment_image_review",
            formData.comment_image_review
         );
         formDataToSend.append("comment_language", formData.comment_language);
         formDataToSend.append("comment_date", formData.comment_date);
         formDataToSend.append("product_id", formData.product_id);

         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/product-add-comment/`,
            formDataToSend,
            {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            }
         );

         if (response.status === 201) {
            setFormData({
               comment_first_name: "",
               comment_last_name: "",
               comment_email: "",
               comment_body_review: "",
               comment_image_review: null,
               comment_rating: 0,
               comment_language: navigator.languages[0].split("-")[0],
               comment_date: moment().tz(timezone).format("YYYY-MM-DD"),
               product_id: params.productId,
            });

            if (fileInputRef.current) {
               fileInputRef.current.value = "";
            }
            setPage(1);
            setDisplayedComments([]);
            setPopUpComment((prevState) => !prevState);
         } else {
            console.error("Error al enviar el comentario:", response.data);
         }
      } catch (error) {
         console.error("Error submitting comment:", error);
      }
   };

   const onChange = (e) => {
      if (e.target.name === "comment_image_review") {
         const file = e.target.files[0];
         if (file) {
            setFormData({ ...formData, comment_image_review: file });
            const url = URL.createObjectURL(file);
            setImagePreview(url);
            console.log(url);
         }
      } else {
         console.log([e.target.name], e.target.value);
         setFormData({ ...formData, [e.target.name]: e.target.value });
      }
   };

   const handleFileChange = () => {
      fileInputRef.current.click();
   };
   const onRatingChange = (newRating) => {
      setFormData({ ...formData, comment_rating: newRating });
   };

   const [popUpComment, setPopUpComment] = useState(false);
   const [focusedField, setFocusedField] = useState("");

   const handleFocus = (field) => {
      setFocusedField(field);
   };

   const handleBlur = (field) => {
      if (!formData[field]) {
         setFocusedField("");
      }
   };

   const handleWriteCommnet = () => {
      setPopUpComment((prevState) => !prevState);
   };

   return (
      <>
         <ProductPopUp
            t={t}
            countProduct={countProduct}
            productData={productData}
            popUpProduct={popUpProduct}
            popUpBackground={popUpBackground}
            popUpProductWarning={popUpProductWarning}
            setPopUpProductWarning={setPopUpProductWarning}
            setPopUpProduct={setPopUpProduct}
            setPopUpComment={setPopUpComment}
            setPopUpBackground={setPopUpBackground}
            language={language}
         />
         <Layout>
            <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-[1fr,minmax(0,380px)] lg:grid-cols-[minmax(0,1fr),minmax(0,380px)]  max-w-[1903px] relative mx-auto pt-1">
               <div className="grid grid-cols-[repeat(1,minmax(0,1fr))] place-items-center justify-start items-start grid-container mx-auto">
                  <div className=" md:hidden w-full h-full">
                     <Swiper
                        className="w-full max-w-full h-full"
                        spaceBetween={0}
                        slidesPerView={1}
                     >
                        {productData &&
                           productData.swiper.slice().map((item, index) => (
                              <SwiperSlide
                                 key={index}
                                 className="w-full h-full"
                              >
                                 <img
                                    className="object-cover w-full max-w-full"
                                    src={`${process.env.REACT_APP_API_URL}${item.productImage}`}
                                    alt={`Product image ${index + 1}`}
                                 />
                              </SwiperSlide>
                           ))}
                     </Swiper>
                  </div>

                  <div className="hidden md:flex w-full mb-2">
                     {productData && (
                        <img
                           className="object-cover max-w-full max-h-screen"
                           // className="object-cover md:h-screen mx-auto"
                           src={`${process.env.REACT_APP_API_URL}${productData.swiper[0].productImage}`}
                           alt={`Product image `}
                        />
                     )}
                  </div>
                  <div className="hidden md:flex md:items-start md:justify-end md:mb-10 gap-2">
                     {productData &&
                        productData.swiper.slice().map(
                           (item, index) =>
                              index !== 0 && (
                                 <div className="w-full" key={index}>
                                    <img
                                       className="object-cover max-w-full max-h-[510px]"
                                       src={`${process.env.REACT_APP_API_URL}${item.productImage}`}
                                       alt={`Product image ${index + 1}`}
                                    />
                                 </div>
                              )
                        )}
                  </div>
                  <div className="hidden w-full max-w-[500px] mx-auto md:block">
                     {productData &&
                        productData.metadata.map((item, index) => (
                           <div className="w-full h-full pb-20" key={index}>
                              <p className="font-Inter font-normal -tracking-0.4px text-center px-5 mb-5">
                                 {item.productDescription}
                              </p>
                              <div className="w-full">
                                 <img
                                    className="w-full h-full object-cover image-product"
                                    src={`${process.env.REACT_APP_API_URL}${item.productImage}`}
                                    alt={`Product Image`}
                                 />
                              </div>
                           </div>
                        ))}
                  </div>
               </div>
               <div className="w-full h-full flex justify-start">
                  <div className="w-full  relative h-full">
                     <div className="w-full px-6 md:px-6 py-14  sticky top-0">
                        <div className="pb-24">
                           <div className="w-full pb-3 border-b-1 border-b-[#e5e5e5] border border-x-transparent border-t-transparent mb-6">
                              {productData && (
                                 <header className="w-full pb-1">
                                    <h2 className="font-Inter not-italic  font-medium  text-3xl  sm:text-3xl md:text-[25px] md:leading-[32px] md:pr-11 ">
                                       {
                                          productData.product.translations[
                                             language
                                          ].productName
                                       }
                                    </h2>
                                    <span className="font-Inter not-italic font-normal -tracking-[0.5px]  text-xl sm:text-2xl md:text-base text-[#757575] ">
                                       {
                                          productData.product.translations[
                                             language
                                          ].productCategory
                                       }
                                    </span>
                                 </header>
                              )}
                              <div className="flex justify-start items-center max-h-[50px]">
                                 {allComments && (
                                    <>
                                       <div className="flex justify-center items-center">
                                          <RenderStars
                                             width={5}
                                             height={5}
                                             averageRating={parseInt(
                                                allComments.averageRating
                                             )}
                                          />
                                       </div>
                                       <h3 className="ml-2 font-Inter not-italic font-normal text-base left-line relative pl-2 max-h-[20px] flex items-center">
                                          {allComments.totalComments} Reviews
                                       </h3>
                                    </>
                                 )}
                              </div>
                           </div>
                           <div className="flex flex-col items-start justify-start pb-3">
                              {/* <span className="font-HelveticaNueThin font-thin text-lg tracking-0.25rem pb-2 text-gray-900">
                                 {t("productDetails.productDetailsMessageText")}
                              </span> */}
                              <div className="flex flex-col items-baseline">
                                 {productData && (
                                    <>
                                       <p className="p-0 font-HelveticaNue font-normal tracking-0.25rem text-2xl mr-2">
                                          USD $
                                          {productData.product.productPrice}
                                       </p>
                                       <div className="flex items-center">
                                          <span className="font-Inter font-normal -tracking-[0.5px]  text-sm mr-1 text-[#565659]">List price:</span>
                                          <p className="mr-3 discount font-Inter font-normal text-sm -tracking-[0.5px] text-[#565659]">
                                             ${productData.product.productOriginalPrice}
                                          </p>
                                       </div>
                                    </>
                                 )}
                              </div>
                           </div>
                           <button
                              className="bg-black text-white py-4 md:py-[14px] font-Inter not-italic font-semibold  text-sm rounded-full w-full max-w-[900px]"
                              onClick={handleAddBag}
                           >
                              {t("productDetails.productDetailsButton")}
                           </button>
                        </div>
                        <div className="w-full">
                           <div className="flex flex-col justify-center items-center bg-[#f5f5f7] py-7 rounded-md mb-4">
                              <ShieldCheckIcon className="w-7 h-7 mr-3" />
                              <h2 className="font-Inter font-medium -tracking-[0.5px] text-[#6D6D6D] text-lg">
                                 {t(
                                    "productCards.productCardFirst.productCardTitle"
                                 )}
                              </h2>
                              <div className="px-5">
                                 <p className="font-Inter font-normal -tracking-[0.5px] text-sm py-2 text-[#A0A0A0]">
                                    {t(
                                       "productCards.productCardFirst.productCardDescriptionOne"
                                    )}
                                 </p>
                                 <p className="font-Inter font-normal -tracking-[0.5px] text-sm py-2 text-[#A0A0A0]">
                                    {t(
                                       "productCards.productCardFirst.productCardDescriptionTwo"
                                    )}
                                 </p>
                              </div>
                           </div>

                           <div className="flex flex-col justify-center items-center bg-[#f5f5f7] py-5 rounded-md">
                              <img
                                 className="w-7 h-7 mr-3"
                                 alt={t(
                                    "productCards.productCardSecond.productCardTitle"
                                 )}
                                 src={Package}
                              />

                              <h2 className="font-Inter font-medium -tracking-[0.5px] text-[#6D6D6D] text-lg">
                                 {t(
                                    "productCards.productCardSecond.productCardTitle"
                                 )}
                              </h2>
                              <ul className="w-full px-5">
                                 <li className="flex items-center justify-start pt-3 pb-4">
                                    <div className="w-auto h-auto flex justify-center items-center mr-3">
                                       <img
                                          className="w-3 h-3 object-cover"
                                          src={Check}
                                       />
                                    </div>
                                    <p className="font-Inter font-normal -tracking-[0.5px] text-[#A0A0A0]  text-sm py-1">
                                       {t(
                                          "productCards.productCardSecond.productCardDescriptionOne"
                                       )}
                                    </p>
                                 </li>
                                 <li className="flex items-center justify-start">
                                    <div className="w-auto h-auto flex justify-center items-center mr-3">
                                       <img
                                          className="w-3 h-3 object-cover"
                                          src={Check}
                                       />
                                    </div>
                                    <p className="font-Inter font-normal -tracking-[0.5px] text-[#A0A0A0] text-sm py-1">
                                       {t(
                                          "productCards.productCardSecond.productCardDescriptionTwo"
                                       )}
                                    </p>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="block w-full max-w-[500px] mx-auto md:hidden px-10">
               {productData &&
                  productData.metadata.map((item, index) => (
                     <div className="w-full h-full pb-20" key={index}>
                        <p className="font-Inter font-normal -tracking-0.4px text-center px-5 mb-5">
                           {item.productDescription}
                        </p>
                        <div className="w-full">
                           <img
                              className="w-full h-full object-cover image-product"
                              src={`${process.env.REACT_APP_API_URL}${item.productImage}`}
                              alt={`Product Image`}
                           />
                        </div>
                     </div>
                  ))}
            </div>
            <Comments
               allComments={allComments}
               displayedComments={displayedComments}
               popUpComment={popUpComment}
               handleFocus={handleFocus}
               handleBlur={handleBlur}
               handleFileChange={handleFileChange}
               handleSubmit={handleSubmit}
               handleWriteCommnet={handleWriteCommnet}
               onChange={onChange}
               onRatingChange={onRatingChange}
               formData={formData}
               focusedField={focusedField}
               fileInputRef={fileInputRef}
               imagePreview={imagePreview}
               page={page}
               setPage={setPage}
               totalPages={totalPages}
            />
         </Layout>
      </>
   );
};

export default React.memo(Product);
