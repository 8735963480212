import { useSelector } from "react-redux";
import Layout from "../../hocs/Layout";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeProductQuantity, clearProductToBag, toggleLanguage } from '../../features/bag/bagSlice'
import { useDispatch } from "react-redux";
import axios from "axios";

import '../../styles/bag.css'

import { useTranslation } from "react-i18next";

const Bag = () => {
    const { t } = useTranslation("bag");
    const dispatch = useDispatch();
    const { products, userUUID, countProduct } = useSelector(state => state.bag);
    const language = useSelector((state) => state.bag.language);

    const getProductCategory = (product) => {
        if (language) {
            if (product.productTranslations && product.productTranslations[language]) {
                return product.productTranslations[language].productCategory || 'Category Not Available';
            }
            return 'Category Not Available';
        } else {
            return 'Category Not Available';
        }
    };

    const getProductName = (product) => {
        if (language) {
            if (product.productTranslations && product.productTranslations[language]) {
                return product.productTranslations[language].productName || 'Category Not Available';
            }
            return 'Category Not Available';
        } else {
            return 'Category Not Available';
        }
    };

    
    const [clientCartDetails, setClientCartDetails] = useState(null);

    const clientCartDetail = async (uuid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: { client_uuid: uuid }
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-detail-bag/`, config);
            if (response.status === 200) {
                setClientCartDetails(response.data);
            }
        } catch (err) {
        }
    };

    useEffect(() => {
        // dispatch(toggleLanguage());
        if (userUUID) {
            clientCartDetail(userUUID);
        }
    }, [products, countProduct, userUUID]);

    const handleProductQuantityChange = async (e, productId) => {
        const currentValue = parseInt(e.target.value);

        const changes = {
            currentValue: currentValue,
            productId: productId,
        };

        dispatch(changeProductQuantity(changes));
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        
        const body = {
            client_uuid: userUUID,
            product_uuid: productId,
            product_quantity: currentValue,
        };
    
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/client-update-bag/`, body, config);
            updateDetails();
        } catch (err) {
            console.error('Error updating the cart:', err);
        }
    };
    const handleClearProductToBag = async (productId) =>{
        dispatch(clearProductToBag({productId: productId}));
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const body = {
            client_uuid: userUUID,
            product_uuid: productId
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/client-remove-product-to-bag/`, body, config);
            updateDetails();
        } catch (err) {
            console.error('Error updating the cart:', err);
        }
    }
    const updateDetails = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: { client_uuid: userUUID }
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-detail-bag/`,config);
            if (response.status === 200) {
                setClientCartDetails(response.data);
            } else {
                console.error('Error en la respuesta:', response.status);
            }
        } catch (err) {
            console.error('Error fetching cart details:', err);
        }
    };

    return (
        <Layout>
            <div className="grid-bag-card-wrapper">
                <div className="w-full h-full grid-bag-card-product-left">
                    <div className="w-full h-full">
                        <div className="w-full p-4 grid-bag-header">
                            <h2 className="font-HelveticaNueThin text-2xl tracking-0.25rem border-b-black pb-3 grid-bag-header-title">{t("header.headerTitle")}</h2>
                        </div>
                        {
                            products && products.length > 0 ? (
                                products.map((product) => (
                                    <div className="bag-product" key={product.productId}>
                                        <div className="bag-product-product-left-wrapper">
                                            <img className="card-product__image" src={`${process.env.REACT_APP_API_URL}${product.productThumbnail}`} alt={getProductName(product)}/>
                                        </div>
                                        <div className="bag-product-right-wrapper">
                                            <header className="px-2 pb-1">
                                                {/* <span className="font-HelveticaNueThin tracking-0.25rem bag-product-right-category">{getProductCategory(product)}</span> */}
                                                {/* <h3 className="font-HelveticaNueRegular text-xl tracking-0.25rem bag-product-right-title">{getProductName(product)}</h3> */}
                                                <span className="font-HelveticaNueThin text-gray-950 leading-none tracking-0.25rem text-lg md:text-lg">{getProductCategory(product)}</span>
                                                <h3 className="font-HelveticaNueRegular tracking-0.25rem text-xl md:text-2xl">{getProductName(product)}</h3>
                                            </header>
                                            <div className="px-2">
                                                <span className="font-HelveticaNueRegular text-xl pb-3 text-left bag-product-right-price">USD ${product.productPrice}</span>
                                            </div>
                                            <footer className="p-2">
                                                <span className="font-HelveticaNueThin text-xs md:text-base tracking-0.25rem cursor-pointer" onClick={() => handleClearProductToBag(product.productId)}>{t("product.productFooterText")}</span>
                                                <select
                                                    className="font-HelveticaNueThin text-xs md:text-base bag-product-right-selection"
                                                    value={product.productQuantity}
                                                    onChange={(e) => handleProductQuantityChange(e, product.productId)}
                                                >
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((quantity) => (
                                                        <option key={quantity} value={quantity}>
                                                            {quantity}
                                                        </option>
                                                    ))}
                                                </select>
                                            </footer>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="w-full">
                                    <h2 className="font-HelveticaNueRegular tracking-0.25rem pl-4">{t("product.productMessageText")}</h2>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="w-full h-full grid-bag-card-product-right">
                    <div className="w-full">
                        {
                            clientCartDetails && (
                                <div className="w-full">
                                    <div className="flex justify-between pb-3">
                                        <span className="font-HelveticaNueThin text-sm tracking-0.25rem">{t("bagDetails.bagDetailsSubTotalText")}</span>
                                        <span className="font-HelveticaNueThin text-sm tracking-0.25rem">
                                            {clientCartDetails.subtotal === "0.00" ? "—" : ` USD ${clientCartDetails.subtotal}`}
                                        </span>
                                    </div>
                                    <div className="flex justify-between pb-6 grid-bag-card-product-right-body">
                                        <span className="font-HelveticaNueThin text-sm tracking-0.25rem">{t("bagDetails.bagDetailsShipmentText")}</span>
                                        <span className="font-HelveticaNueThin text-sm tracking-0.25rem">
                                            {clientCartDetails.subtotal === "0.00" ? "—" : ` USD 3.00`}
                                        </span>
                                    </div>
                                    <div className="flex justify-between pt-4 pb-5">
                                        <span className="font-HelveticaNueThin text-lg tracking-0.25rem bag-detail-total">{t("bagDetails.bagDetailsTotalText")}</span>
                                        <span className="font-HelveticaNueThin text-lg tracking-0.25rem bag-detail-total-price">
                                            {clientCartDetails.subtotal === "0.00" ? "—" : ` USD ${clientCartDetails.totalPrice}`}
                                        </span>
                                    </div>
                                    {
                                        clientCartDetails.subtotal !== "0.00" ?  
                                            <Link to='/Checkout' className="w-full h-16">
                                                <button className="w-full h-auto text-white bg-black px-5 py-4 rounded-full font-HelveticaNueThin text-xs sm:text-xs md:text-sm tracking-0.25rem transition-all duration-300 ease-in">
                                                {t("bagDetails.bagDetailsButtonText")}
                                                </button>
                                            </Link>
                                        : 
                                        <Link to='/' className="w-full h-16">
                                            <button className="w-full h-auto text-gray-400 bg-transparent px-5 py-5 rounded-full font-HelveticaNueThin text-xs sm:text-xs md:text-sm tracking-0.25rem transition-all duration-300 ease-in">
                                                {t("bagDetails.bagDetailsButtonText")}
                                            </button>
                                        </Link>

                                    }

                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default Bag;