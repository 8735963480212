import Layout from "../../hocs/Layout";
import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../styles/checkout.css";

const Contact = () => {
   const [focusedField, setFocusedField] = useState("");
   const [sentMessage, setSentMessage] = useState(false);
   const language = useSelector((state) => state.bag.language);

   const { t } = useTranslation("contact");

   const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
   });

   const [formStyles, setFormStyles] = useState({
      firstName: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      lastName: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      email: { borderColor: "border-inputColor", spanColor: "text-inputColor" },
      message: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
   });

   const onChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
         ...prevState,
         [name]: value,
      }));

      if (value === "") {
         setFormStyles((prevState) => ({
            ...prevState,
            [name]: {
               borderColor: "border-red-500",
               spanColor: "text-red-500",
            },
         }));
      } else {
         setFormStyles((prevState) => ({
            ...prevState,
            [name]: {
               borderColor: "border-black",
               spanColor: "text-black",
            },
         }));
      }
   };

   const handleOnFocusForm = (field) => {
      setFocusedField(field);
   };

   const handleBlurFocusForm = (field) => {
      setFocusedField("");
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      const body = {
         data: formData,
      };

      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };

      try {
         // Utiliza `await` para esperar la respuesta de `axios.post`
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/send-email/`,
            body,
            config
         );

         // Si la respuesta es exitosa, maneja la lógica correspondiente
         console.log("Email sent successfully:", response);
         setSentMessage(true);
         setFormData({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
         });
      } catch (error) {
         // Captura y maneja correctamente el error
         console.error("Error sending email:", error);
      }
   };

   return (
      <>
         <Layout>
            <div className="max-w-screen-sm mx-auto p-3 pt-1 ">
               <div className="py-14">
                  <h1 className="font-HelveticaNueRegular text-4xl tracking-0.25rem">
                     {t("contact.contactHeader")}
                  </h1>
               </div>
               <div className="w-full pb-10 ">
                  <form className="w-full" onSubmit={handleSubmit}>
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text  form-input-border ${formStyles.firstName.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                              focusedField === "firstName" || formData.firstName
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.firstName.spanColor}`}
                        >
                           {t("contact.firstName")}
                        </span>
                        <input
                           required
                           type="text"
                           name="firstName"
                           value={formData.firstName}
                           className="w-full outline-none"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("firstName")}
                           onBlur={() => handleBlurFocusForm("firstName")}
                        />
                     </label>
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text  form-input-border ${formStyles.lastName.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                              focusedField === "lastName" || formData.lastName
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.lastName.spanColor}`}
                        >
                           {t("contact.lastName")}
                        </span>
                        <input
                           required
                           type="text"
                           name="lastName"
                           value={formData.lastName}
                           className="w-full outline-none"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("lastName")}
                           onBlur={() => handleBlurFocusForm("lastName")}
                        />
                     </label>
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text  form-input-border ${formStyles.email.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                              focusedField === "email" || formData.email
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.email.spanColor}`}
                        >
                           {t("contact.email")}
                        </span>
                        <input
                           required
                           type="email"
                           name="email"
                           value={formData.email}
                           className="w-full outline-none"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("email")}
                           onBlur={() => handleBlurFocusForm("email")}
                        />
                     </label>
                     <label className="relative max-h-48 min-h-48 h-full w-full block mb-5">
                        {formData.message != "" ? (
                           <></>
                        ) : (
                           <span
                              className={`absolute top-5 left-2 font-HelveticaNueThin ${formStyles.message.spanColor}`}
                           >
                              {t("contact.textarea")}
                           </span>
                        )}

                        <textarea
                           required
                           className={`font-HelveticaNueThin w-full border px-2 pt-5 outline-none h-48 max-h-48 min-h-48 form-input-border rounded-md ${formStyles.message.borderColor}`}
                           name="message"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("message")}
                           onBlur={() => handleBlurFocusForm("message")}
                           value={formData.message}
                        />
                     </label>
                     <button className="font-HelveticaNueThin bg-black text-white px-20 py-2 tracking-0.25rem rounded-md w-full ">
                        {t("contact.button")}
                     </button>
                  </form>
               </div>
               {sentMessage ? (
                  <div className="border border-t-gray-100 py-5 border-b-transparent border-x-transparent">
                     <h3 className="text-center font-HelveticaNueRegular tracking-0.25rem text-green-500">
                        {t("contact.messageLeft")}
                        <span className="text-green-500">
                           {t("contact.messageRight")}
                        </span>
                     </h3>
                  </div>
               ) : null}
            </div>
         </Layout>
      </>
   );
};

export default Contact;
