import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";

import Product from "./containers/pages/Product";
import Home from "./containers/pages/Home";
import Bag from "./containers/pages/Bag";
import Success from "./containers/pages/Success";
import Checkout from "./containers/pages/Checkout";
import Contact from "./containers/pages/Contact";
import { ErrorPage } from "./containers/errors/ErrorPage";

import { setUserUUID, setLanguage } from "./features/bag/bagSlice";
import { useSelector } from "react-redux";

import axios from "axios";


const PrivateRoute = ({ element, condition, redirectPath = '/' }) => {
   return condition ? element : <Navigate to={redirectPath} />;
};

const App = () => {
   const dispatch = useDispatch();

   const { countProduct } = useSelector(state => state.bag);
   
   const languageBrowser = () => {
      let language = navigator.languages[0].split("-")[0];
      if (language == "en") {
         language = "english";
      } else if (language == "es") {
         language = "spanish";
      }
      return language;
   };
   const setClient = async (uuid) => {
      const config = {
         headers: {
            "Content-Type": "application/json",
         },
      };
      const body = JSON.stringify({
         client_uuid: uuid,
      });
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/client-create/`,
            body,
            config
         );
         return response.data;
      } catch (error) {
         console.error(
            "Error al crear el usuario:",
            error.response ? error.response.data : error.message
         );
      }
   };

   useEffect(() => {
      const storedUUID = localStorage.getItem("userUUID");
      const userUUID = storedUUID || uuidv4();
      if (!storedUUID) {
         localStorage.setItem("userUUID", userUUID);
      }
      dispatch(setUserUUID({ userUUID }));
      const detectedLanguage = languageBrowser();
      dispatch(setLanguage(detectedLanguage));
      setClient(userUUID);
   }, [dispatch]);

   return (
      <Router>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Product/:productId" element={<Product />} />
            <Route path="/Bag" element={<Bag />} />

            {/* <Route path="/Checkout" element={<Checkout />} /> */}
            <Route 
               path="/Checkout" 
               element={
                  <PrivateRoute 
                  element={<Checkout />} 
                  condition={countProduct >= 1} 
                  redirectPath="/" 
                  />
               } 
            />
            <Route path="/Success" element={<Success />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="*" element={<ErrorPage />} />
         </Routes>
      </Router>
   );
};

export default App;
