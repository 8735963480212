import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import HeaderHome from "../../components/HeaderHome";
import "../../styles/home.css";

import { Link } from "react-router-dom";
import { setLinkParam } from "../../features/bag/bagSlice";
// import imageHero from '../../assets/images/hero/Thumbnail.png'
import imageHero from "../../assets/images/hero/hero_cat.png";
import imageHero960 from "../../assets/images/hero/hero_960px.png";

import { useTranslation } from "react-i18next";

const Home = () => {
   const { t } = useTranslation("home");
   const [products, setProducts] = useState([]);
   const dispatch = useDispatch();
   const { userUUID } = useSelector((state) => state.bag);

   useEffect(() => {
      window.scrollTo(0, 0);
      let userLanguage = navigator.language || navigator.languages[0];
      userLanguage = userLanguage.split("-")[0];

      const productList = async () => {
         const config = {
            headers: {
               "Content-Type": "application/json",
               "Accept-Language": userLanguage,
            },
         };
         try {
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/product-list/`,
               config
            );
            dispatch(setLinkParam(response.data[0].productId));
            setProducts(response.data);
         } catch (err) {
            console.error("Error al obtener productos:", err);
         }
      };

      productList();
   }, [userUUID]);

   return (
      <>
         <main className="main-home">
            <HeaderHome />
            <div className="w-full h-[50vh] sm:w-10/12 sm:h-[50vh] md:w-11/12  md:h-[90dvh] mx-auto">
               <div className="overflow-hidden w-full h-full relative rounded-xl md:rounded-2xl">
                  <div className="absolute w-full h-full px-6 py-5 md:px-28 md:py-24 hero-section-text ">
                     <h2 className="font-IntegralCFBold  text-black text-4xl md:text-8xl whitespace-pre-line">
                        {t("hero.title")}
                     </h2>
                     <h3 className="font-IntegralCFBold text-black text-xl md:text-5xl whitespace-pre-line pb-5">
                        {t("hero.subtitle")}
                     </h3>
                     {products.length > 0 && (
                        <>
                           <Link
                              to={`/Product/${products[0].productId}`}
                              className="font-HelveticaNueExtraBold text-white text-base md:text-xl bg-black px-9 py-3 md:px-14 md:py-4 rounded-md"
                              title={`${products[0].translations.productName}`}
                           >
                              {t("hero.heroLink")}
                           </Link>
                        </>
                     )}
                  </div>
                  <img
                     className="w-full h-full object-cover absolute top-0 hidden md:block"
                     alt={t("hero.heroLink")}
                     src={imageHero}
                  />
                  <img
                     className="w-full h-full object-cover absolute top-0 block md:hidden"
                     src={imageHero960}
                  />
               </div>
            </div>
         </main>
      </>
   );
};

export default Home;
