import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import '../styles/header.css';
import { copyResponse } from 'workbox-core'
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation("header");
    const { countProduct, catalogParam } = useSelector(state => state.bag)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [classHeader, setClassHeader] = useState("");
    const [ openNavMobile, setOpenNavMobile] = useState(false);

    const handleScroll = () => {
        const currentScrollPosition = window.scrollY;
    
        if (currentScrollPosition === 0) {
            setClassHeader("");
        } else if (currentScrollPosition > scrollPosition) {
            if (currentScrollPosition > 10) {
                setClassHeader("-screen-hidden");
            }
        } else if (currentScrollPosition < scrollPosition) {
            setClassHeader("-screen-show");
        }
    
        setScrollPosition(currentScrollPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);


    const handlerNavMobileClose = () => {
        setOpenNavMobile(false);
        document.body.style.overflowY = "scroll";
        setClassHeader("");
    }
    const handlerNavMobileOpen = () => {
        setOpenNavMobile(true);
        document.body.style.overflowY = "hidden";
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }

    return (
        <>
            <div className='w-full h-[3.5rem]'>
                <div className={`nav-menu-open-${ openNavMobile ? "show" : "hidden"}`}>
                    <div className={`nav-menu-open-wrapper-${ openNavMobile ? "show" : "hidden"}`}>
                        <nav className="bg-white w-full h-full">
                            <div className='flex flex-col justify-start items-end w-full h-full p-6'>
                                <XMarkIcon className='w-6 mb-12' onClick={handlerNavMobileClose}/>
                                <Link className='font-HelveticaNueThin w-full text-xl flex items-center justify-between p-2 tracking-0.4px' to="/" onClick={handlerNavMobileClose}>{t("nav.navHome")}<ChevronRightIcon className='w-6'/></Link>
                                <Link className='font-HelveticaNueThin w-full text-xl flex items-center justify-between p-2 tracking-0.4px' to="/Contact/" onClick={handlerNavMobileClose}>{t("nav.navContact")}<ChevronRightIcon className='w-6'/></Link>
                                <Link className='font-HelveticaNueThin w-full text-xl flex items-center justify-between p-2 tracking-0.4px' to={`/Product/${catalogParam}`} onClick={handlerNavMobileClose}>{t("nav.navCatalog")}<ChevronRightIcon className='w-6'/></Link>
                            </div>
                        </nav>
                    </div>
                </div>
                <header className={`header${classHeader}`}>
                    <div className='px-10 flex header-wrapper'>
                        <div className='flex-1 h-full flex justify-start items-center'>
                            <span className='font-HelveticaNueThin tracking-0.4px text-lg'>V I A R</span>
                        </div>

                        <nav className='flex-1 h-ful flex items-end justify-end'>
                            <div className='w-auto h-full nav-device-desktop'>
                                <ul className='w-full h-full flex justify-center items-center'>
                                    <li className='font-HelveticaNueThin tracking-0.4px text-xs cursor-pointer'><Link className='w-full h-full' to="/">{t("nav.navHome")}</Link></li>
                                    <li className='font-HelveticaNueThin tracking-0.4px text-xs pl-7 cursor-pointer'><Link className='w-full h-full' to="/Contact/">{t("nav.navContact")}</Link></li>
                                    <li className='font-HelveticaNueThin tracking-0.4px text-xs pl-7 pr-7 cursor-pointer'><Link className='w-full h-full' to={`/Product/${catalogParam}`}>{t("nav.navCatalog")}</Link></li>
                                </ul>
                            </div>
                            <div className='w-auto h-full'>
                                <ul className='flex w-full h-full justify-center items-center'>
                                    <li className=' cursor-pointer'>
                                        <MagnifyingGlassIcon className='w-5 h-5 md:w-5 md:h-5 lg:w-5 lg:h-5'/>
                                    </li>
                                    <li className='pl-7 cursor-pointer relative'>
                                        <Link className='w-full h-full' to='/bag'>                                
                                            <ShoppingBagIcon className='w-5 h-5 md:w-5 md:h-5 lg:w-5 lg:h-5'/>
                                            {
                                                countProduct > 0 && countProduct < 10 ? (
                                                    <span className='count-product-header'>{countProduct}</span>
                                                ) : countProduct >= 10 ? (
                                                    <span className='count-product-header'>9+</span>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                    <li className='pl-7 cursor-pointer nav-device-mobile' onClick={handlerNavMobileOpen}><Bars3Icon className='w-6 h-6 md:w-6 md:h-6 lg:w-5 lg:h-5'/></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        </>
        
    )

}

export default Header;