import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
   Elements,
   CardNumberElement,
   CardExpiryElement,
   CardCvcElement,
   useStripe,
   useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "../../styles/checkout.css";
import Layout from "../../hocs/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutForm = () => {
   const { t } = useTranslation("checkout");
   const [checkoutDetail, setCheckoutDetail] = useState(null);
   const language = useSelector((state) => state.bag.language);
   const { userUUID, products } = useSelector((state) => state.bag);
   const navigate = useNavigate();

   useEffect(() => {
      const clientBag = async () => {
         try {
            const config = {
               headers: {
                  "Content-Type": "application/json",
               },
            };
            const params = {
               client_uuid: userUUID,
            };
            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/client-detail-bag/`,
               {
                  headers: config.headers,
                  params,
               }
            );
         } catch (error) {
            console.error("Error:", error);
         }
      };
      const checkoutDetails = async () => {
         try {
            const config = {
               headers: {
                  "Content-Type": "application/json",
               },
               params: { client_uuid: userUUID },
            };

            const response = await axios.get(
               `${process.env.REACT_APP_API_URL}/client-detail-bag/`,
               config
            );
            if (response.status === 200) {
               setCheckoutDetail(response.data);
            } else {
               console.error("Error en la respuesta:", response.status);
            }
         } catch (err) {
            console.error("Error fetching cart details:", err);
         }
      };

      if (userUUID) {
         clientBag();
         checkoutDetails();
      }
   }, [userUUID]);

   const stripe = useStripe();
   const elements = useElements();
   // const [clientSecret, setClientSecret] = useState("");
   const [error, setError] = useState(null);
   const [success, setSuccess] = useState(false);

   const [focusedField, setFocusedField] = useState("");

   const [cardNumberIsFocus, setCardNumberIsFocus] = useState(false);
   const [cardNumberHasValue, setCardNumberHasValue] = useState(false);
   const [cardNumberBorderColor, setCardNumberBorderColor] =
      useState("border-inputColor");
   const [cardNumberSpanColor, setCardNumberSpanColor] =
      useState("text-inputColor");

   const [expiryFocus, setExpiryFocus] = useState(false);
   const [expiryHasValue, setExpiryHasValue] = useState(false);
   const [expiryBorderColor, setExpiryBorderColor] =
      useState("border-inputColor");
   const [expirySpanColor, setExpirySpanColor] = useState("text-inputColor");

   const [cvcFocus, setCvcFocus] = useState(false);
   const [cvcHasValue, setCvcHasValue] = useState(false);
   const [cvcBorderColor, setCvcBorderColor] = useState("border-inputColor");
   const [cvcSpanColor, setCvcSpanColor] = useState("text-inputColor");

   const handleFocus = (setter) => {
      setter(true);
   };

   const handleBlur = (setter) => {
      setter(false);
   };

   const handleChange = (event, setter, borderSetter, spanSetter) => {
      // console.log("valor", event.empty);
      setter(!event.empty);
      if (event.empty) {
         spanSetter("text-red-500");
         borderSetter("border-red-500");
      } else {
         spanSetter("text-black");
         borderSetter("border-black");
      }
   };

   const [formData, setFormData] = useState({
      email: "",
      country: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      depto: "",
      colony: "",
   });

   const resetState = () => {
      formData.state = "";
   };

   const statesMx = [
      { value: "Aguascalientes", label: "Aguascalientes" },
      { value: "Baja California", label: "Baja California" },
      { value: "Baja California Sur", label: "Baja California Sur" },
      { value: "Campeche", label: "Campeche" },
      { value: "Chiapas", label: "Chiapas" },
      { value: "Chihuahua", label: "Chihuahua" },
      { value: "Coahuila", label: "Coahuila" },
      { value: "Colima", label: "Colima" },
      { value: "Durango", label: "Durango" },
      { value: "Guanajuato", label: "Guanajuato" },
      { value: "Guerrero", label: "Guerrero" },
      { value: "Hidalgo", label: "Hidalgo" },
      { value: "Jalisco", label: "Jalisco" },
      { value: "Estado de México", label: "Estado de México" },
      { value: "Michoacán", label: "Michoacán" },
      { value: "Morelos", label: "Morelos" },
      { value: "Nayarit", label: "Nayarit" },
      { value: "Nuevo León", label: "Nuevo León" },
      { value: "Oaxaca", label: "Oaxaca" },
      { value: "Puebla", label: "Puebla" },
      { value: "Querétaro", label: "Querétaro" },
      { value: "Quintana Roo", label: "Quintana Roo" },
      { value: "San Luis Potosí", label: "San Luis Potosí" },
      { value: "Sinaloa", label: "Sinaloa" },
      { value: "Sonora", label: "Sonora" },
      { value: "Tabasco", label: "Tabasco" },
      { value: "Tamaulipas", label: "Tamaulipas" },
      { value: "Tlaxcala", label: "Tlaxcala" },
      { value: "Veracruz", label: "Veracruz" },
      { value: "Yucatán", label: "Yucatán" },
      { value: "Zacatecas", label: "Zacatecas" },
   ];

   const countries = [
      { value: "US", label: "United States" },
      { value: "MX", label: "Mexico" },
      // Agrega más países según sea necesario
   ];

   const [formStyles, setFormStyles] = useState({
      firstName: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      lastName: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      email: { borderColor: "border-inputColor", spanColor: "text-inputColor" },
      colony: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      address: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      city: { borderColor: "border-inputColor", spanColor: "text-inputColor" },
      state: { borderColor: "border-inputColor", spanColor: "text-inputColor" },
      zipCode: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      country: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
      depto: {
         borderColor: "border-inputColor",
         spanColor: "text-inputColor",
      },
   });

   const handleOnChangeCountry = (e) => {
      const { name, value } = e.target;

      // Actualizar formData
      setFormData((prevState) => ({
         ...prevState,
         [name]: value,
         state: value === "US" ? "" : prevState.state, // Limpia el estado si el país es US
      }));

      // Actualizar estilos de formulario solo para el campo country
      setFormStyles((prevState) => ({
         ...prevState,
         country: {
            borderColor: value === "" ? "border-red-500" : "border-black",
            spanColor: value === "" ? "text-red-500" : "text-black",
         },
      }));
   };

   const onChange = (e) => {
      const { name, value } = e.target;

      // Actualizar formData
      setFormData((prevState) => ({
         ...prevState,
         [name]: value,
      }));

      // Actualizar estilos de formulario
      if (value === "") {
         setFormStyles((prevState) => ({
            ...prevState,
            [name]: {
               borderColor: "border-red-500",
               spanColor: "text-red-500",
            },
         }));
      } else {
         setFormStyles((prevState) => ({
            ...prevState,
            [name]: {
               borderColor: "border-black",
               spanColor: "text-black",
            },
         }));
      }
   };

   const handleOnFocusForm = (field) => {
      setFocusedField(field);
      // setFormStyles((prevState) => ({
      //    ...prevState,
      //    [field]: {
      //       borderColor: "border-inputColor",
      //       spanColor: "text-inputColor",
      //    },
      // }));
   };
   const handleBlurFocusForm = (field) => {
      setFocusedField("");
      // setFormStyles((prevState) => ({
      //    ...prevState,
      //    [field]: {
      //       borderColor: formData[field] ? "border-black" : "border-red-500",
      //       spanColor: formData[field] ? "text-black" : "text-red-500",
      //    },
      // }));
   };

   const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
         return;
      }

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/create-payment-intent/`,
            {
               language: language,
               currency: "mxn",
               client: userUUID,
               items: products,
            }
         );
         const clientSecret = response.data.clientSecret;

         const billingDetails = {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            address: {
               line1: formData.address,
               city: formData.city,
               state: formData.state,
               postal_code: formData.zipCode,
               country: formData.country,
            },
         };

         const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
               payment_method: {
                  card: elements.getElement(CardNumberElement),
                  billing_details: billingDetails,
               },
            }
         );

         if (error) {
            setError(error.message);
            return;
         }

         if (paymentIntent.status === "succeeded") {
            setSuccess(true);
            setError(null);

            const body = {
               formData,
               client: userUUID,
               products: products,
            };

            try {
               await axios.post(
                  `${process.env.REACT_APP_API_URL}/save-ticket/`,
                  body
               );
            } catch (error) {
               console.log("Error al guardar el ticket:", error);
            }
         }
      } catch (error) {
         setError("Hubo un problema con el proceso de pago.");
      }
   };

   // Maneja la redirección en un `useEffect` o similar si estás usando React
   useEffect(() => {
      if (success) {
         navigate("/Success/");
      }
   }, [success]);

   return (
      <>
         <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-2xl mx-auto">
            <div className="border border-r-gray-100 border-y-transparent border-l-transparent">
               <div className="max-w-md mx-auto p-6">
                  <h1 className="font-HelveticaNueRegular text-3xl">
                     {t("form.formTitle")}
                  </h1>
               </div>
               <form
                  onSubmit={handleSubmit}
                  className="max-w-md mx-auto p-6 bg-white "
               >
                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text  form-input-border ${formStyles.firstName.borderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                           focusedField === "firstName" || formData.firstName
                              ? "-top"
                              : "-hidden"
                        } ${formStyles.firstName.spanColor}`}
                     >
                        {t("form.formFirstName")}
                     </span>
                     <input
                        required
                        name="firstName"
                        value={formData.firstName}
                        className="w-full outline-none"
                        onChange={onChange}
                        onFocus={() => handleOnFocusForm("firstName")}
                        onBlur={() => handleBlurFocusForm("firstName")}
                     />
                  </label>
                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text ${formStyles.lastName.borderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                           focusedField === "lastName" || formData.lastName
                              ? "-top"
                              : "-hidden"
                        } ${formStyles.lastName.spanColor}`}
                     >
                        {t("form.formLastName")}
                     </span>
                     <input
                        name="lastName"
                        required
                        value={formData.lastName}
                        className="w-full outline-none"
                        onChange={onChange}
                        onFocus={() => handleOnFocusForm("lastName")}
                        onBlur={() => handleBlurFocusForm("lastName")}
                     />
                  </label>
                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text ${formStyles.email.borderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                           focusedField === "email" || formData.email
                              ? "-top"
                              : "-hidden"
                        } ${formStyles.email.spanColor}`}
                     >
                        {t("form.formEmail")}
                     </span>
                     <input
                        name="email"
                        required
                        value={formData.email}
                        className="w-full outline-none"
                        onChange={onChange}
                        onFocus={() => handleOnFocusForm("email")}
                        onBlur={() => handleBlurFocusForm("email")}
                     />
                  </label>
                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text ${formStyles.address.borderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                           focusedField === "address" || formData.address
                              ? "-top"
                              : "-hidden"
                        } ${formStyles.address.spanColor}`}
                     >
                        {t("form.formStreet")}
                     </span>
                     <input
                        name="address"
                        required
                        value={formData.address}
                        className="w-full outline-none"
                        onChange={onChange}
                        onFocus={() => handleOnFocusForm("address")}
                        onBlur={() => handleBlurFocusForm("address")}
                     />
                  </label>
                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text ${formStyles.depto.borderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                           focusedField === "depto" || formData.depto
                              ? "-top"
                              : "-hidden"
                        } ${formStyles.depto.spanColor}`}
                     >
                        {t("form.formSuite")}
                     </span>
                     <input
                        name="depto"
                        value={formData.depto}
                        className="w-full outline-none"
                        onChange={onChange}
                        onFocus={() => handleOnFocusForm("depto")}
                        onBlur={() => handleBlurFocusForm("depto")}
                     />
                  </label>

                  <div className="mb-4">
                     <label
                        htmlFor="country"
                        className={`block ${formStyles.country.spanColor} font-HelveticaNueThin tracking-0.25rem`}
                     >
                        {/* Country */}
                     </label>
                     <select
                        id="country"
                        name="country"
                        value={formData.country}
                        required
                        onChange={handleOnChangeCountry}
                        className={`border ${formStyles.country.borderColor} ${formStyles.country.spanColor} w-full outline-none font-HelveticaNueThin tracking-0.25rem px-2 py-4 rounded-md bg-transparent`}
                     >
                        <option value="" disabled>
                           {t("form.formCountry")}
                        </option>
                        {countries.map((country) => (
                           <option
                              key={country.value}
                              value={country.value}
                              className="font-HelveticaNueThin tracking-0.25rem"
                           >
                              {country.label}
                           </option>
                        ))}
                     </select>
                  </div>
                  {formData.country == "MX" ? (
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md cursor-text ${formStyles.colony.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                              focusedField === "colony" || formData.colony
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.colony.spanColor}`}
                        >
                           {t("form.formColony")}
                        </span>
                        <input
                           name="colony"
                           value={formData.colony}
                           className="w-full outline-none"
                           onChange={onChange}
                           required
                           onFocus={() => handleOnFocusForm("colony")}
                           onBlur={() => handleBlurFocusForm("colony")}
                        />
                     </label>
                  ) : (
                     <></>
                  )}

                  <div className="flex gap-3 ">
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border rounded-md h-14 cursor-text form-input-border ${formStyles.zipCode.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                              focusedField === "zipCode" || formData.zipCode
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.zipCode.spanColor}`}
                        >
                           {t("form.formZipCode")}
                        </span>
                        <input
                           name="zipCode"
                           required
                           value={formData.zipCode}
                           className="w-full outline-none"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("zipCode")}
                           onBlur={() => handleBlurFocusForm("zipCode")}
                        />
                     </label>
                     <label
                        className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative border cursor-text h-14 rounded-md form-input-border ${formStyles.city.borderColor}`}
                     >
                        <span
                           className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                              focusedField === "city" || formData.city
                                 ? "-top"
                                 : "-hidden"
                           } ${formStyles.city.spanColor}`}
                        >
                           {t("form.formCity")}
                        </span>
                        <input
                           name="city"
                           required
                           value={formData.city}
                           className="w-full outline-none"
                           onChange={onChange}
                           onFocus={() => handleOnFocusForm("city")}
                           onBlur={() => handleBlurFocusForm("city")}
                        />
                     </label>
                     {formData.country == "US" ? (
                        <label
                           className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative cursor-text border h-14 rounded-md form-input-border ${formStyles.state.borderColor}`}
                        >
                           <span
                              className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                                 focusedField === "state" || formData.state
                                    ? "-top"
                                    : "-hidden"
                              } ${formStyles.state.spanColor}`}
                           >
                              {t("form.formState")}
                           </span>
                           <input
                              name="state"
                              required
                              value={formData.state}
                              className="w-full outline-none"
                              onChange={onChange}
                              onFocus={() => handleOnFocusForm("state")}
                              onBlur={() => handleBlurFocusForm("state")}
                           />
                        </label>
                     ) : (
                        <select
                           id="statesMX"
                           name="state"
                           value={formData.state}
                           onChange={onChange}
                           className={`border outline-none rounded-md bg-transparent p-0 m-0 h-14 pt-5 pb-2 px-2 max-w-36 font-HelveticaNueThin tracking-0.25rem ${formStyles.state.borderColor} ${formStyles.state.spanColor}`}
                        >
                           <option value="" disabled>
                              {t("form.formState")}
                           </option>
                           {statesMx.map((state) => (
                              <option
                                 key={state.value}
                                 value={state.label}
                                 className={`font-HelveticaNueThin p-0 m-0 `}
                              >
                                 {state.label}
                              </option>
                           ))}
                        </select>
                     )}
                  </div>

                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 cursor-text relative border rounded-md form-input-border ${cardNumberBorderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                           cardNumberIsFocus || cardNumberHasValue
                              ? "-top"
                              : "-hidden"
                        } ${cardNumberSpanColor}`}
                     >
                        {t("form.formCardNumber")}
                     </span>
                     <CardNumberElement
                        className="w-full h-full font-HelveticaNueThin"
                        onChange={(e) =>
                           handleChange(
                              e,
                              setCardNumberHasValue,
                              setCardNumberBorderColor,
                              setCardNumberSpanColor
                           )
                        }
                        onFocus={() => handleFocus(setCardNumberIsFocus)}
                        onBlur={() => handleBlur(setCardNumberIsFocus)}
                        options={{
                           style: {
                              base: {
                                 fontSize: "16px",
                                 color: "#000",
                                 "::placeholder": {
                                    color: "transparent",
                                 },
                              },
                              invalid: {
                                 color: "#000",
                              },
                           },
                        }}
                     />
                  </label>

                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative cursor-text border rounded-md form-input-border ${expiryBorderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 select-none input-number-card${
                           expiryFocus || expiryHasValue ? "-top" : "-hidden"
                        } ${expirySpanColor}`}
                     >
                        {t("form.formExpiryDate")}
                     </span>
                     <CardExpiryElement
                        className="w-full h-full font-HelveticaNueThin"
                        onChange={(e) =>
                           handleChange(
                              e,
                              setExpiryHasValue,
                              setExpiryBorderColor,
                              setExpirySpanColor
                           )
                        }
                        onFocus={() => handleFocus(setExpiryFocus)}
                        onBlur={() => handleBlur(setExpiryFocus)}
                        options={{
                           style: {
                              base: {
                                 fontSize: "16px",
                                 color: "#000",
                                 "::placeholder": {
                                    color: "transparent",
                                 },
                              },
                              invalid: {
                                 color: "#000",
                              },
                           },
                        }}
                     />
                  </label>

                  <label
                     className={`flex justify-start items-center mb-4 pt-5 pb-2 px-2 relative cursor-text border rounded-md form-input-border ${cvcBorderColor}`}
                  >
                     <span
                        className={`font-HelveticaNueThin absolute left-2 text-inputColor select-none input-number-card${
                           cvcFocus || cvcHasValue ? "-top" : "-hidden"
                        } ${cvcSpanColor}`}
                     >
                        {t("form.formCVC")}
                     </span>
                     <CardCvcElement
                        className="w-full h-full font-HelveticaNueThin"
                        onChange={(e) =>
                           handleChange(
                              e,
                              setCvcHasValue,
                              setCvcBorderColor,
                              setCvcSpanColor
                           )
                        }
                        onFocus={() => handleFocus(setCvcFocus)}
                        onBlur={() => handleBlur(setCvcFocus)}
                        options={{
                           style: {
                              base: {
                                 fontSize: "16px",
                                 color: "#000",
                                 "::placeholder": {
                                    color: "transparent",
                                 },
                              },
                              invalid: {
                                 color: "#000",
                              },
                           },
                        }}
                     />
                  </label>

                  <div className="w-full h-auto block md:hidden">
                     <div className="w-full pb-3">
                        <div className="w-full">
                           <h2 className="font-HelveticaNueThin text-2xl tracking-0.25rem pb-3">
                              {t("order.orderTitle")}
                           </h2>
                        </div>
                        <div className="w-full">
                           {checkoutDetail && (
                              <>
                                 <div className="flex justify-between w-full">
                                    <span className="font-HelveticaNueThin">
                                       {t("order.orderSubtotal")}
                                    </span>
                                    <span className="font-HelveticaNueThin">
                                       ${checkoutDetail.subtotal}
                                    </span>
                                 </div>
                                 <div className="flex justify-between w-full pb-2">
                                    <span className="font-HelveticaNueThin">
                                       {t("order.orderEstimatedShipping")}
                                    </span>
                                    <span className="font-HelveticaNueThin">
                                       ${checkoutDetail.shippingCost}
                                    </span>
                                 </div>
                                 <div className="flex justify-between w-full">
                                    <span className="font-HelveticaNueThin">
                                       {t("order.orderTotal")}
                                    </span>
                                    <span className="font-HelveticaNueThin">
                                       ${checkoutDetail.totalPrice}
                                    </span>
                                 </div>
                              </>
                           )}
                        </div>
                     </div>
                     {products &&
                        products.map((product, index) => (
                           <div className="w-full h-60" key={index}>
                              <div className="w-full max-w-sm h-40 flex flex-col md:flex-row items-start md:items-start">
                                 <img
                                    className="w-40 h-40  object-cover"
                                    src={`${process.env.REACT_APP_API_URL}${product.productThumbnail}`}
                                    alt={product.productName}
                                 />
                                 <div className="w-full h-full px-4 pt-2 flex flex-col justify-start items-start">
                                    <h2 className="font-HelveticaNueThin break-words text-lg tracking-0.25rem">
                                       {product.productTranslations &&
                                       product.productTranslations[language] &&
                                       product.productTranslations[language]
                                          .productName
                                          ? product.productTranslations[
                                               language
                                            ].productName
                                          : "Nombre no disponible"}
                                    </h2>
                                    <span className="font-HelveticaNueThin text-zinc-700 break-words text-base ">
                                       $
                                       {product.productPrice
                                          ? product.productPrice
                                          : "0"}{" "}
                                       USD
                                    </span>
                                 </div>
                              </div>
                           </div>
                        ))}
                  </div>

                  <button
                     type="submit"
                     disabled={!stripe}
                     className="w-full bg-black text-white py-2 rounded-md disabled:cursor-not-allowed"
                  >
                     {t("form.formButton")}
                  </button>
                  {error && <div className="mt-4 text-red-500">{error}</div>}
                  {success && (
                     <div className="mt-4 text-green-500">
                        Pago realizado con éxito
                     </div>
                  )}
               </form>
            </div>
            <div className="hidden md:block p-6">
               <div className="max-w-80 pb-3">
                  <div className="w-full">
                     <h2 className="font-HelveticaNueThin text-2xl tracking-0.25rem pb-3">
                        {t("order.orderTitle")}
                     </h2>
                  </div>
                  <div className="w-full">
                     {checkoutDetail && (
                        <>
                           <div className="flex justify-between w-full">
                              <span className="font-HelveticaNueThin">
                                 {t("order.orderSubtotal")}
                              </span>
                              <span className="font-HelveticaNueThin">
                                 ${checkoutDetail.subtotal}
                              </span>
                           </div>
                           <div className="flex justify-between w-full pb-2">
                              <span className="font-HelveticaNueThin">
                                 {t("order.orderEstimatedShipping")}
                              </span>
                              <span className="font-HelveticaNueThin">
                                 ${checkoutDetail.shippingCost}
                              </span>
                           </div>
                           <div className="flex justify-between w-full">
                              <span className="font-HelveticaNueThin">
                                 {t("order.orderTotal")}
                              </span>
                              <span className="font-HelveticaNueThin">
                                 ${checkoutDetail.totalPrice}
                              </span>
                           </div>
                        </>
                     )}
                  </div>
               </div>
               {products &&
                  products.map((product, index) => (
                     <div className="" key={index}>
                        <div className="w-full max-w-sm h-40 flex flex-col md:flex-row items-start md:items-start">
                           <img
                              className="w-40 h-40  object-cover"
                              src={`${process.env.REACT_APP_API_URL}${product.productThumbnail}`}
                              alt={product.productName}
                           />
                           <div className="w-full h-full px-4 pt-2 flex flex-col justify-start items-start">
                              <h2 className="font-HelveticaNueThin break-words text-lg tracking-0.25rem">
                                 {product.productTranslations &&
                                 product.productTranslations[language] &&
                                 product.productTranslations[language]
                                    .productName
                                    ? product.productTranslations[language]
                                         .productName
                                    : "Nombre no disponible"}
                              </h2>
                              <span className="font-HelveticaNueThin text-zinc-700 break-words text-base ">
                                 $
                                 {product.productPrice
                                    ? product.productPrice
                                    : "0"}{" "}
                                 USD
                              </span>
                           </div>
                        </div>
                     </div>
                  ))}
            </div>
         </div>
      </>
   );
};

const App = () => (
   <Layout>
      <Elements stripe={stripePromise}>
         <CheckoutForm />
      </Elements>
   </Layout>
);

export default App;
