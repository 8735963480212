// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enHeader from './translations/en/header.json';
import enHome from './translations/en/home.json';
import enProduct from './translations/en/product.json'
import enBag from './translations/en/bag.json';
import enCheckout from './translations/en/checkout.json'
import enContact from './translations/en/contact.json'

import esHeader from './translations/es/header.json';
import esHome from './translations/es/home.json';
import esProduct from './translations/es/product.json';
import esBag from './translations/es/bag.json'
import esCheckout from './translations/es/checkout.json'
import esContact from './translations/es/contact.json'

i18n
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(initReactI18next)
  .init({
    resources: {
      en: { header: enHeader, home: enHome, product: enProduct, bag: enBag, checkout: enCheckout, contact: enContact, },
      es: { header: esHeader, home: esHome, product: esProduct, bag: esBag, checkout: esCheckout, contact: esContact, },
    },
    fallbackLng: 'en', // Idioma por defecto si no se detecta uno compatible
    interpolation: {
      escapeValue: false,
    },
    detection: {
      // Opcionalmente, puedes configurar opciones adicionales para el detector
      order: ['navigator'], // Se usa para detectar el idioma del navegador
    },
  });

export default i18n;