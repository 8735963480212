import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import RenderStars from "../Products/ViewStarRating";

const CommentPopUp = ({
   imagePopUp,
   setImagePopUp,
   commentOnClose,
   setCommentOnClose,
   commentPayload,
   setCommentPayload,
   //    commentClassPopUp,
   //    setCommentClassPopUp,
}) => {
   const handleClose = () => {
      document.body.style.overflowY = "auto";
      setCommentOnClose(true);
      setCommentPayload("")
      //   setCommentClassPopUp("bg-transparent pointer-events-none");
      setImagePopUp(null);
   };
   return (
      <div
         className={`w-full h-screen ${
            commentOnClose
               ? "bg-transparent pointer-events-none"
               : "pointer-events-auto overflow-auto bg-black"
         }
         fixed top-0 z-1 flex justify-center items-center`}
      >
         <div className="w-full h-full relative">
            <div
               className={`absolute w-full h-64 p-5 ${
                  commentOnClose ? "" : "bg-black-to-transparent"
               }`}
            >
               <div className="w-full flex justify-end">
                  <XMarkIcon
                     className={`text-white w-7 h-7 cursor-pointer ${
                        commentOnClose ? "hidden" : "block"
                     }`}
                     onClick={handleClose}
                  />
               </div>
               <header
                  className={`w-full px-3 flex flex-col justify-end items-start`}
               >
                  <span className="font-HelveticaNueRegular text-white md:text-xl tracking-0.25rem">
                     
                  {commentOnClose ? "" : commentPayload.formattedName}
                  </span>
                  {commentOnClose ? (
                     <></>
                  ) : (
                     <div className="pb-3">
                        <RenderStars
                           width={4}
                           height={4}
                           color="white"
                           averageRating={commentPayload.commentRating}
                        />
                     </div>
                  )}
                  <span className="font-HelveticaNueRegular text-xs text-white md:max-w-2xl md:text-base tracking-0.25rem">
                     {commentPayload.commentBodyReview}
                  </span>
               </header>
            </div>
            <div className="w-full h-full p-2 flex justify-center items-center">
               {commentOnClose ? (
                  <></>
               ) : (
                  <img
                     className="w-full max-w-full h-screen md:w-auto object-cover border-none"
                     src={imagePopUp}
                  />
               )}
            </div>
         </div>
      </div>
   );
};

{
   /* <div className="w-full h-full p-2 flex justify-center items-center border-none">
<img
   className="w-full max-w-full h-screen md:max-w-6xl object-cover border-none"
   src={imagePopUp}
/>
</div> */
}
export default CommentPopUp;
