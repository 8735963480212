import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import Header from "../components/Header";
import Header from "../components/Header";
const Layout = (props) => {
    

    return (
        <>  
            <Header/>
            {/* <ToastContainer autoClose={5000} /> */}
            {props.children}
        </>
    );
}

export default Layout;