import { StarIcon } from "@heroicons/react/24/solid";
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/solid';

const RenderStars = ({ averageRating, width = 6, height = 6, color = "black"}) => {
    const validRating = Math.min(Math.max(parseInt(averageRating, 10) || 0, 0), 5);

    const fullStars = validRating;
    const emptyStars = 5 - fullStars;

    return (
        <div className="flex items-center">
            {/* Renderiza estrellas llenas */}
            {[...Array(fullStars)].map((_, index) => (
                <StarIcon
                    key={`full-${index}`}
                    className={`w-${width} h-${height} text-${color}`}
                />
            ))}
            {/* Renderiza estrellas vacías */}
            {[...Array(emptyStars)].map((_, index) => (
                <StarOutlineIcon
                    key={`empty-${index}`}
                    className={`w-${width} h-${height} text-gray-100`}
                />
            ))}
        </div>
    );
};

export default RenderStars;