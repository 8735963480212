import { createSlice } from '@reduxjs/toolkit';
import { changeLanguage } from 'i18next';

const savedProducts = localStorage.getItem('products');
const products = savedProducts ? JSON.parse(savedProducts) : [];
const countProduct = products.reduce((total, product) => total + product.productQuantity, 0);

const initialState = {
    userUUID: "",
    countProduct: countProduct,
    products: products,
    catalogParam: "",
    language: "",
};

export const bagSlice = createSlice({
    name: 'bag',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        addProductToBag: (state, action) => {
            const currentProduct = action.payload;
            const existingProduct = state.products.find(
                (product) => product.productId === currentProduct.productId
            );
            if (existingProduct) {
                existingProduct.productQuantity += currentProduct.productQuantity;
            } else {
                state.products.push(currentProduct);
            }
            state.countProduct += currentProduct.productQuantity;

            localStorage.setItem('products', JSON.stringify(state.products));
        },
        changeProductQuantity: (state, action) => {
            const { productId, currentValue } = action.payload;
            const product = state.products.find(product => product.productId === productId);
            if (product) {
                product.productQuantity = currentValue;
            }
            
            // Actualiza countProduct basado en los productos en el carrito
            state.countProduct = state.products.reduce((total, product) => total + product.productQuantity, 0);
            
            // Guarda el estado actualizado en el localStorage
            localStorage.setItem('products', JSON.stringify(state.products));
        },
        setUserUUID: (state, action) => {
            state.userUUID = action.payload.userUUID;
            localStorage.setItem('userUUID', state.userUUID); // Actualizar localStorage
        },
        clearProductToBag: (state, action) => {
            state.products = [];
            state.countProduct = 0;
            localStorage.setItem('products', JSON.stringify(state.products));
        },
        setLinkParam: (state, action) => {
            state.catalogParam = action.payload
        }
    },
});

export const { addProductToBag, setUserUUID, clearProductToBag, changeProductQuantity, setLinkParam, setLanguage } = bagSlice.actions;

export default bagSlice.reducer;
