import React, { useEffect, useState, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import "../../styles/popup.css";

const ProductPopUp = ({ popUpProduct, language, popUpBackground, popUpProductWarning,setPopUpProduct, setPopUpBackground, setPopUpProductWarning, t, productData, countProduct }) => {
   
   const handleClosePopUp = () => {
      setPopUpProduct(false);
      setPopUpBackground(false);
      setPopUpProductWarning(false);
   };

   useEffect(() => {
      if (popUpProduct) {
         const timer = setTimeout(() => {
            setPopUpBackground(false);
            setPopUpProduct(false);
         }, 2000);
         return () => clearTimeout(timer);
      }
   }, [popUpProduct]);

   useEffect(() => {
      if (popUpProductWarning) {
         const timer = setTimeout(() => {
            setPopUpBackground(false);
            setPopUpProductWarning(false);
         }, 2000);
         return () => clearTimeout(timer);
      }
   }, [popUpProductWarning]);

   

   return (
      <div className={`popup-wrapper-${popUpBackground ? "show" : "hidden"}`}>
         {/* <div
      className={`popup-wrapper-show`}
   > */}
         <div className={`popup-product-${popUpProduct ? "show" : "hidden"}`}>
            {/* <div
         className={`popup-product-show`}
      > */}
            <header className="popup-product-header">
               <h3 className="popup-product-header-title">
                  {t("popUp.popUpHeaderTitle")}
               </h3>
               <XMarkIcon
                  className="popup-product-header-x-mark cursor-pointer"
                  onClick={handleClosePopUp}
               />
            </header>
            <div className="popup-product-header-body ">
               <div className="popup-product-header-body-left">
                  {productData && (
                     <img
                        className="popup-product-header-body-image"
                        src={`${process.env.REACT_APP_API_URL}${productData.product.productThumbnail}`}
                        alt="Product Card"
                     />
                  )}
               </div>
               <div className=" popup-product-header-body-right">
                  {productData && (
                     <>
                        <div className="w-full">
                           <h3 className="popup-product-header-body-right-title text-base">
                              {
                                 productData.product.translations[language].productName
                              }
                           </h3>
                        </div>
                        <span className="popup-product-header-body-right-price font-HelveticaNueRegular">
                           <span className="icon-right-price font-HelveticaNueRegular">
                              $
                           </span>
                           {productData.product.productPrice}
                        </span>
                        <span className="popup-product-header-body-right-quantity font-HelveticaNueRegular">{`${t(
                           "popUp.popUpBodyText"
                        )} 1`}</span>
                     </>
                  )}
               </div>
            </div>
            <footer className="popup-product-header-footer">
               <Link to="/Bag" className="popup-product-header-footer-btn">
                  <button className="w-full h-full">{`${t(
                     "popUp.popUpFooterButtonLeft"
                  )} (${countProduct})`}</button>
               </Link>
               <button className="popup-product-header-footer-btn">
                  {t("popUp.popUpFooterButtonRight")}
               </button>
            </footer>
         </div>
         <div
            className={`popup-product-warning-${
               popUpProductWarning ? "show" : "hidden"
            }`}
         >
            <header className="popup-product-warning-header flex items-center justify-between">
               <h2 className="popup-product-warning-header-title">
                  {t("popUpWarning.popUpWarningHeaderTitle")}
               </h2>
               <XMarkIcon
                  className="popup-product-header-x-mark"
                  onClick={handleClosePopUp}
               />
            </header>
            <div className="popup-product-warning-body">
               <h2 className="popup-product-warning-body-text">
                  {t("popUpWarning.popUpWarningBodyText")}
               </h2>
            </div>
         </div>
      </div>
   );
};

export default ProductPopUp;
